//

/**
 * 字段描述
 * drawer 是否选择风格，只有广告魔方为true
 * notTitle 是否需要标题输入框
 * notImg 是否需要上传图片
 * notAdd 是否可以新增
 * notLink 是否需要链接
 * close 是否可以删除选项
 *
 * */

export const modelData = [
  {
    type: 'carousel',
    name: '图片轮播',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/banner.png',
    img: 'md-image',
    notTitle: true,
    close: true,
    drawer: false,
    notAdd: false,
    notImg: false,
    notLink: false,
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/banner1.jpg',
          url: '',
          extraParams: {
            videoSrc: null,
            isVideo: false,
          },
          link: [],
          size: '750*350',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 750, // 默认生成截图框宽度
            autoCropHeight: 350, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/banner1.jpg',
          url: '',
          link: [],
          extraParams: {
            videoSrc: null,
            isVideo: false,
          },
          size: '750*350',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: false, // 是否默认生成截图框
            autoCropWidth: 750, // 默认生成截图框宽度
            autoCropHeight: 350, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/banner1.jpg',
          url: '',
          link: [],
          extraParams: {
            videoSrc: null,
            isVideo: false,
          },
          size: '750*350',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: false, // 是否默认生成截图框
            autoCropWidth: 750, // 默认生成截图框宽度
            autoCropHeight: 350, // 默认生成截图框高度
            fixedBox: false, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'tab',
    name: 'tab标签栏',
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/tab.png',
    notTitle: false,
    close: true,
    drawer: false,
    notAdd: false,
    notImg: true,
    notLink: false,
    attributeParams: {
      background: '#0f4fc7',
    },
    options: {
      list: [
        {
          img: 'https://i.loli.net/2020/12/05/SoGAv7gYybuWzED.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
        },
        {
          img: 'https://i.loli.net/2020/12/05/JXR5K3sbIeENjqH.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
        },
        {
          img: 'https://i.loli.net/2020/12/05/KlZfnCFIdEV231Y.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
        },
        {
          img: 'https://i.loli.net/2020/12/05/GfmMyN2wrUVIlci.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
        },
        {
          img: 'https://i.loli.net/2020/12/05/eznDa3iNby5FkYL.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
        },
      ],
    },
  },
  {
    type: 'publish',
    name: '动态',
    drawer: false,
    img: 'md-image',
    notTitle: false,
    close: false,
    drawer: false,
    notAdd: true,
    notImg: true,
    notLink: true,
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/dt.png',
    options: {
      list: [
        {
          title: '动态',
        },
      ],
    },
  },
  {
    type: 'grid',
    name: '宫格导航',
    img: 'md-image',
    notTitle: false,
    close: true,
    drawer: false,
    notAdd: false,
    notImg: false,
    notLink: false,
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/grid.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid1.jpg',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 88, // 默认生成截图框宽度
            autoCropHeight: 88, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid2.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 88, // 默认生成截图框宽度
            autoCropHeight: 88, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid3.jpg',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 88, // 默认生成截图框宽度
            autoCropHeight: 88, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid4.jpg',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 88, // 默认生成截图框宽度
            autoCropHeight: 88, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/admin/img/grid5.png',
          url: '',
          link: [],
          title: '标题',
          size: '88*88 (1:1)',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 88, // 默认生成截图框宽度
            autoCropHeight: 88, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'richText',
    name: '富文本模式',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: true,
    notLink: false,
    isRich: true,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/richText.png',
    options: {
      list: [
        {
          richText: '',
          url: '',
          link: [],
          size: '335*335',
        },
      ],
    },
  },

  {
    type: 'leftOneRightTwo',
    name: '左一右二',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    view: 'tpl_ad_list',
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/leftOneRightTwo.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340320.png',
          url: '',
          link: [],
          size: '340*320',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340150.png',
          url: '',
          link: [],
          size: '340*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340150.png',
          url: '',
          link: [],
          size: '340*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },

  {
    type: 'leftTwoRightOne',
    name: '左二右一',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/leftTwoRightOne.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340150.png',
          url: '',
          link: [],
          size: '340*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340150.png',
          url: '',
          link: [],
          size: '340*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340320.png',
          url: '',
          link: [],
          size: '340*320',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'leftOneRightOne',
    name: '左一右一',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/leftOneRightOne.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/leftOneRightOneBg.png',
          url: '',
          link: [],
          size: '340*210',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 210, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/leftOneRightOneBg.png',
          url: '',
          link: [],
          size: '340*210',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 210, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'flexThree',
    name: '三列单行',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/flexThree.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/220150.png',
          url: '',
          link: [],
          size: '220*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 220, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/220150.png',
          url: '',
          link: [],
          size: '220*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 220, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/220150.png',
          url: '',
          link: [],
          size: '220*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 220, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'flexOne',
    name: '一张大图',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/flexOne.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702.png',
          url: '',
          link: [],
          extraParams: {
            videoSrc: null,
            isVideo: false,
          },
          size: '702*不限制',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 750, // 默认生成截图框宽度
            autoCropHeight: 1800, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'flexTwo',
    name: '两张横图',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/flexTwo.png',
    img: 'md-image',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702180.png',
          url: '',
          link: [],
          size: '702*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [4, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702180.png',
          url: '',
          link: [],
          size: '702*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [4, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'topOneBottomTwo',
    name: '上一下二',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/topOneBottomTwo.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702180.png',
          url: '',
          link: [],
          size: '702*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [4, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340180.png',
          url: '',
          link: [],
          size: '340*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340180.png',
          url: '',
          link: [],
          size: '340*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 320, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'topTwoBottomOne',
    name: '上二下一',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/topTwoBottomOne.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340180.png',
          url: '',
          link: [],

          size: '340*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 180, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/340180.png',
          url: '',
          link: [],
          size: '340*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 340, // 默认生成截图框宽度
            autoCropHeight: 180, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702180.png',
          url: '',
          link: [],
          size: '702*180',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 702, // 默认生成截图框宽度
            autoCropHeight: 180, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'flexFive',
    name: '五列单行',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/flexFive.png',
    img: 'md-image',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/134150.png',
          url: '',
          link: [],
          size: '134*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 134, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/134150.png',
          url: '',
          link: [],
          size: '134*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 134, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/134150.png',
          url: '',
          link: [],
          size: '134*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 134, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/134150.png',
          url: '',
          link: [],
          size: '134*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 134, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/134150.png',
          url: '',
          link: [],
          size: '134*150',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 134, // 默认生成截图框宽度
            autoCropHeight: 150, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'flexFour',
    name: '四列单行',
    drawer: true,
    notAdd: true,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/flexFour.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/168156.png',
          url: '',
          link: [],
          size: '168*156',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 168, // 默认生成截图框宽度
            autoCropHeight: 156, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/168156.png',
          url: '',
          link: [],
          size: '168*156',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 168, // 默认生成截图框宽度
            autoCropHeight: 156, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/168156.png',
          url: '',
          link: [],
          size: '168*156',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 168, // 默认生成截图框宽度
            autoCropHeight: 156, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/168156.png',
          url: '',
          link: [],
          size: '168*156',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 168, // 默认生成截图框宽度
            autoCropHeight: 156, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },
  {
    type: 'textPicture',
    name: '文字图片',
    notTitle: true,
    close: false,
    drawer: true,
    notAdd: true,
    notImg: false,
    notLink: false,
    img: 'md-image',
    icon: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/textPicture.png',
    options: {
      list: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702400.png',
          url: '',
          link: [],
          size: '702*400',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 702, // 默认生成截图框宽度
            autoCropHeight: 400, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/cms/bg/702400.png',
          url: '',
          link: [],
          size: '702*400',
          options: {
            outputSize: 1, // 裁剪生成图片的质量
            outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
            info: true, // 裁剪框的大小信息
            canScale: true, // 图片是否允许滚轮缩放
            autoCrop: true, // 是否默认生成截图框
            autoCropWidth: 702, // 默认生成截图框宽度
            autoCropHeight: 400, // 默认生成截图框高度
            fixedBox: true, // 固定截图框大小 不允许改变
            fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
            fixedNumber: [2, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
            canMove: true, // 上传图片是否可以移动
            canMoveBox: true, // 截图框能否拖动
            original: false, // 上传图片按照原始比例渲染
            centerBox: false, // 截图框是否被限制在图片里面
            infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            full: true, // 是否输出原图比例的截图
            enlarge: '1', // 图片根据截图框输出比例倍数
            mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
          },
        },
      ],
    },
  },

  // {
  //   type: 'advertise',
  //   name: '广告魔方',
  //   img: 'md-image',
  //   notTitle: true,
  //   close: false,
  //   drawer: false,
  //   notAdd: true,
  //   notImg: true,
  //   notLink: true,
  //   options: {
  //     list: [],
  //   },
  // },

  // {
  //   type: 'search',
  //   name: '搜索',
  //   drawer: false,
  //   img: 'md-image',
  //   notTitle: false,
  //   close: false,
  //   drawer: false,
  //   notAdd: true,
  //   notImg: true,
  //   notLink: true,
  //   options: {
  //     list: [
  //       {
  //         title: '搜索',
  //       },
  //     ],
  //   },
  // },
]
